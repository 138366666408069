.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.Landing-Heading {
  background: linear-gradient(
    to right,
    #007BFF 20%,
    #A700FF 30%,
    #FF1744 70%,
    #FF9100 80%
);
font-size: 200px;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
background-size: 500% auto;
animation: textShine 3s ease-in-out infinite alternate;

}

.Landing-Sub-Heading {
  display: inline-block;
  overflow: hidden;
  /* border-right: 2px solid #007BFF;  */
  white-space: nowrap;
  margin: 0;
  letter-spacing: 2px; /* Adjust as needed */
  animation: typing 3s steps(30) infinite, blink-caret 0.75s step-end infinite;
  color: blueviolet;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}



@keyframes fade-in-zoom {
  from {
    opacity: 0;
    /* font-size: 10px; */
  }
  to {
    opacity: 1;
    /* font-size: 100px; */
  }
}

@keyframes gradientTransition {
  0%, 100% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
@media screen and (max-width: 768px) {
  .Landing-Heading {
    font-size: 120px;
  }
  
  .Landing-Sub-Heading {
    font-size: 16px; 
  }
}